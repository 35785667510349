<template>
  <div class="classes-container">
    <el-table :data="tableData" style="width: 100%">
      <el-table-column prop="class_info.class_name" label="Class Name" width="250">
        <template slot-scope="scope">
          <el-image
            style="width: 40px; height: 40px; margin-right: 10px; vertical-align: middle;"
            :src="scope.row.class_info.class_avatar_url"
            fit="cover"
          ></el-image>
          {{ scope.row.class_info.class_name }}
        </template>
      </el-table-column>
      <el-table-column prop="class_info.class_BU" label="BU" width="120"></el-table-column>
      <el-table-column prop="class_info.class_description" label="Description" width="250"></el-table-column>
      <el-table-column prop="class_info.class_editor_names" label="Editors" width="120"></el-table-column>
      <el-table-column prop="class_info.session_completion_required" label="Completion Required" width="180"></el-table-column>
      <el-table-column label="Actions" width="120">
        <template slot-scope="scope">
          <el-button
            size="mini"
            @click="handleEdit(scope.$index, scope.row.class_info)">Edit</el-button>
        </template>
      </el-table-column>
    </el-table>
    
    <el-button type="primary" @click="addClass" style="margin-top: 20px;">Add New Class</el-button>
  </div>
</template>

<script>
import {
  postRequest
} from "../api/assistant";
import VueTagsInput from '@johmun/vue-tags-input'
export default {
  components:{
    VueTagsInput
  },
  data() {
    return {
      tableData: [],
      form: {},
      show: false,
      contentData:{},
     
    };
  },
  mounted() {
    this.fetchData();

  },
  methods: {

    add(){
      this.form = {
        id:'',
        title:'',
        hint:'',
        type:'Marketing',
        brand:'WAO',
        status:'IN PLANNING',
        form:'passage',
      }
      this.show = true
    },

    fetchData() {
      
      postRequest({'function':'getClasses'}).then((res) => {
        if (res.data.code === 0) {
          this.tableData = res.data.data;
        }
      });
    },
    
    edit(row){
      this.form = row;
      this.form.id = row._id.$id;
      this.show = true
    },
    
    submit(){
      this.form.function = 'updateAIContent';
      postRequest(this.form).then(res=>{
        if(res.data.code===0){
          this.$message({
            type: "success",
            message: "修改成功!",
          });
          this.show = false;
          this.fetchData();
        }
      })
    },
    viewContent(row){
      this.contentData = row;
      this.showContent = true;
    },
    closeContent(){
      this.contentData = {};
      this.showContent = false;
    } 
    
  },
};
</script>

<style lang="scss">
.vue-tags-input{
  flex: 1!important;
  margin-left: 10px;
  max-width: none!important;
  .ti-input{
    width: 100%!important;
  }
}
</style>
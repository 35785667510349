import request from '../utils/request'
import axios from 'axios'


// Add to your imports/configuration


export function netFetch(param) {
    return request.post('./index.php', param)
}

export function postRequest(params) {
    return axios.post('/index.php', params)
}

export function postAccountRequest(functionName, params) {
    return axios.post('/Account/index.php', {
        function: functionName,
        ...params
    })
}



export function loginByEmail(employee_email, employee_pwd) {
    return request.post('./index.php', {
        function: 'loginByEmailNew',
        user_email: employee_email,
        user_pwd: employee_pwd
    })
}
export function checkUserPwdStatus(employee_email, employee_pwd) {
    return request.post('./index.php', {
        function: 'checkUserPwdStatus',
        user_email: employee_email,
        user_pwd: employee_pwd
    })
}
export function getTODOs(id, type) {
    if (type === 'wechat') {
        return request.post('./index.php', {
            function: 'getTODOs',
            wechat_openID: id
        })
    } else if (type === 'user') {
        return request.post('./index.php', {
            function: 'getTODOs',
            user_id: id
        })
    }
}
export function updateTODO(id, userId, type, content, list_id, todo_labels) {
    if (type === 'wechat') {
        return request.post('./index.php', {
            function: 'updateTODO',
            id: id,
            wechat_openID: userId,
            content: content,
            list_id: list_id,
            todo_labels: todo_labels
        })
    } else if (type === 'user') {
        return request.post('./index.php', {
            function: 'updateTODO',
            id: id,
            user_id: userId,
            content: content,
            list_id: list_id,
            todo_labels: todo_labels
        })
    }
}

export function updateTODONote(id, note) {
    return request.post('./index.php', {
        function: 'updateTODONote',
        id,
        note
    })
}

export function deleteTODO(id) {
    return request.post('./index.php', {
        function: 'deleteTODO',
        id
    })
}

// 获取左侧列表
export function getLists(id, type) {
    if (type === 'wechat') {
        return request.post('./index.php', {
            function: 'getLists',
            wechat_openID: id
        })
    } else if (type === 'user') {
        return request.post('./index.php', {
            function: 'getLists',
            user_id: id
        })
    }
}

export function getProjectLists(id, type) {
    if (type === 'wechat') {
        return request.post('./index.php', {
            function: 'getProjectLists',
            wechat_openID: id
        })
    } else if (type === 'user') {
        return request.post('./index.php', {
            function: 'getProjectLists',
            user_id: id
        })
    }
}

// 更新左侧列表 某一个list 下的list id : new getList 对应
export function updateList(id, userId, type, name, cooperator_ids) {
    if (type === 'wechat') {
        return request.post('./index.php', {
            function: 'updateList',
            id: id,
            wechat_openID: userId,
            name: name,
            cooperator_ids
        })
    } else if (type === 'user') {
        return request.post('./index.php', {
            function: 'updateList',
            id: id,
            user_id: userId,
            name: name,
            cooperator_ids
        })
    }
}
// 获取TODO LIST 列表
export function getTODOsByList(userId, type, list_id) {
    if (type === 'wechat') {
        return request.post('./index.php', {
            function: 'getTODOsByList',
            wechat_openID: userId,
            list_id: list_id
        })
    } else if (type === 'user') {
        return request.post('./index.php', {
            function: 'getTODOsByList',
            user_id: userId,
            list_id: list_id
        })
    }
}
// 获取某一个list的详情
export function getList(id) {
    return request.post('./index.php', {
        function: 'getList',
        id: id
    })
}
export function getTODO(id) {
    return request.post('./index.php', {
        function: 'getTODO',
        id: id
    })
}
export function starTODO(id, user_id) {
    return request.post('./index.php', {
        function: 'starTODO',
        id: id,
        user_id
    })
}
export function unstarTODO(id, user_id) {
    return request.post('./index.php', {
        function: 'unstarTODO',
        id: id,
        user_id
    })
}

export function finishTODO(id, user_id) {
    return request.post('./index.php', {
        function: 'finishTODO',
        id: id,
        user_id
    })
}

export function unfinishTODO(id, user_id) {
    return request.post('./index.php', {
        function: 'unfinishTODO',
        id: id
    })
}
export function updateSubTODO(todo_id, content) {
    return request.post('./index.php', {
        function: 'updateSubTODO',
        todo_id: todo_id,
        content: content
    })
}

export function getAdminProjects() {
    return request.post('./index.php', {
        function: 'getAdminProjects',
    })
}


export function fastTODO(id, user_id) {
    return request.post('./index.php', {
        function: 'fastTODO',
        id: id,
        user_id
    })
}
export function unfastTODO(id, user_id) {
    return request.post('./index.php', {
        function: 'unfastTODO',
        id: id,
        user_id
    })
}
export function finishSubTODO(todo_id, sub_index, user_id) {
    return request.post('./index.php', {
        function: 'finishSubTODO',
        todo_id: todo_id,
        sub_index: sub_index,
        user_id: user_id

    })
}
export function unfinishSubTODO(todo_id, sub_index) {
    return request.post('./index.php', {
        function: 'unfinishSubTODO',
        todo_id: todo_id,
        sub_index: sub_index
    })
}
export function getMyStarTODOS(user_id, finish_status) {
    return request.post('./index.php', {
        function: 'getMyStarTODOs',
        user_id: user_id,
        finish_status: finish_status
    })
}

export function getMyFastTODOs(user_id, finish_status) {
    return request.post('./index.php', {
        function: 'getMyFastTODOs',
        user_id: user_id,
        finish_status: finish_status
    })
}
export function findMyGivenTODOS(user_id, finish_status) {
    return request.post('./index.php', {
        function: 'findMyGivenTODOs',
        user_id: user_id,
        finish_status: finish_status
    })
}
export function getFinishedTODOsByList(userId, type, list_id) {
    if (type === 'wechat') {
        return request.post('./index.php', {
            function: 'getFinishedTODOsByList',
            wechat_openID: userId,
            list_id: list_id
        })
    } else if (type === 'user') {
        return request.post('./index.php', {
            function: 'getFinishedTODOsByList',
            user_id: userId,
            list_id: list_id
        })
    }
}
export function deleteCheckList(param) {
    const paramInfo = Object.assign({}, param, { function: 'deleteCheckList' })
    return request.post('./index.php', paramInfo)
}
export function deleteCheckListItem(param) {
    const paramInfo = Object.assign({}, param, { function: 'deleteCheckListItem' })
    return request.post('./index.php', paramInfo)
}
export function getTypeAllInfo(param) {
    const paramInfo = Object.assign({}, param, { function: 'getTypeAllInfo' })
    return request.post('./index.php', paramInfo)
}
export function resignReviewer(param) {
    const paramInfo = Object.assign({}, param, { function: 'resignReviewer' })
    return request.post('./index.php', paramInfo)
}
export function changeReviewerStatus(param) {
    const paramInfo = Object.assign({}, param, { function: 'changeReviewerStatus' })
    return request.post('./index.php', paramInfo)
}
export function refreshReviewerStatus(param) {
    const paramInfo = Object.assign({}, param, { function: 'refreshReviewerStatus' })
    return request.post('./index.php', paramInfo)
}
export function updateReviewNote(param) {
    const paramInfo = Object.assign({}, param, { function: 'updateReviewNote' })
    return request.post('./index.php', paramInfo)
}

export function getMyMessages(user_id) {
    return request.post('./index.php', {
        function: 'getMyMessages',
        user_id: user_id
    })
}
export function flagTODO(id) {
    return request.post('./index.php', {
        function: 'flagTODO',
        id
    })
}
export function unflagTODO(id) {
    return request.post('./index.php', {
        function: 'unflagTODO',
        id
    })
}
export function getMyFlagTODOs(user_id, finish_status) {
    return request.post('./index.php', {
        function: 'getMyFlagTODOs',
        user_id: user_id,
        finish_status: finish_status
    })
}
export function getMyTentativeTODOs(user_id, finish_status) {
    return request.post('./index.php', {
        function: 'getMyTentativeTODOs',
        user_id: user_id,
        finish_status: finish_status
    })
}
export function tentativeTODO(id) {
    return request.post('./index.php', {
        function: 'tentativeTODO',
        id
    })
}
export function untentativeTODO(id) {
    return request.post('./index.php', {
        function: 'untentativeTODO',
        id
    })
}
export function netService(functionName, params) {
    return request.post('./index.php', {
        function: functionName,
        ...params
    }).then(res => res.data)
}
export function getMyTODOsByLabel(user_id, label, finish_status, list_id) {
    return request.post('./index.php', {
        function: 'getMyTODOsByLabel',
        user_id,
        label,
        finish_status,
        list_id
    })
}
export function getListsCount(user_id) {
    return request.post('./index.php', {
        function: 'getListsCount',
        user_id
    })
}

export function getLabelsByList(list_id) {
    return request.post('./index.php', {
        function: 'getLabelsByList',
        list_id
    })
}
export function moveTODOtoList(todo_id, list_id) {
    return request.post('./index.php', {
        function: 'moveTODOtoList',
        todo_id,
        list_id
    })
}

export function searchTODO(user_id, search_word, finish_status) {
    return request.post('./index.php', {
        function: 'searchTODOs',
        user_id: user_id,
        search_word: search_word,
        finish_status: finish_status

    })
}

export function searchProjectTODO(user_id, search_word, finish_status) {
    return request.post('./index.php', {
        function: 'searchProjectTODOs',
        user_id: user_id,
        search_word: search_word,
        finish_status: finish_status

    })
}
export function getTODOsAssigned(id, type, list_id) {
    if (type === 'wechat') {
        return request.post('./index.php', {
            function: 'getTODOsAssignedByList',
            wechat_openID: id,
            list_id: list_id
        })
    } else if (type === 'user') {
        return request.post('./index.php', {
            function: 'getTODOsAssignedByList',
            user_id: id,
            list_id: list_id
        })
    }
}

export function findEmployeeByEmail(email) {
    return request.post('./index.php', {
        function: 'findEmployeeByEmail',
        email
    })
}

export function updateMeetingNote(id, name, tags, content, participant_emails) {
    return request.post('./index.php', {
        function: 'updateMeetingNote',
        id,
        name,
        tags,
        content,
        participant_emails
    })
}
export function getMyMeetingNote(param) {
    return request.post('./index.php', {
        function: 'getMyMeetingNote',
        ...param
    })
}

export function getMeetingNote(id) {
    return request.post('./index.php', {
        function: 'getMeetingNote',
        id
    })
}

export function updateNewCheckTask(id, type_id, name, note, creater_id, checklist_id) {
    return request.post('./index.php', {
        function: 'updateNewCheckTask',
        id,
        type_id,
        name,
        note,
        creater_id,
        checklist_id

    })
}
export function getCheckTask(taks_id) {
    return request.post('./index.php', {
        function: 'getCheckTask',
        taks_id
    })
}

export function updateCheckType(id, name, tags) {
    return request.post('./index.php', {
        function: 'updateCheckType',
        id,
        name,
        tags
    })
}

export function getCheckTypes() {
    return request.post('./index.php', {
        function: 'getCheckTypes'
    })
}

export function getAllCheckTasks() {
    return request.post('./index.php', {
        function: 'getAllCheckTasks'
    })
}

export function assignReviewerToCheckTask(task_id, reviewer_id, creater_id) {
    return request.post('./index.php', {
        function: 'assignReviewerToCheckTask',
        task_id,
        reviewer_id,
        creater_id

    })
}

export function updateCheckList(id, type_id, name) {
    return request.post('./index.php', {
        function: 'updateCheckList',
        id,
        type_id,
        name
    })
}
export function getListsByType(type_id) {
    return request.post('./index.php', {
        function: 'getListsByType',
        type_id
    })
}

export function getAllCheckLists() {
    return request.post('./index.php', {
        function: 'getAllCheckLists'
    })
}

export function updateCheckListItem(id, list_id, content, valid_status) {
    return request.post('./index.php', {
        function: 'updateCheckListItem',
        id,
        list_id,
        content,
        valid_status
    })
}
export function checkTaskItem(reviewer_id, item_id, task_id) {
    return request.post('./index.php', {
        function: 'checkTaskItem',
        reviewer_id,
        item_id,
        task_id
    })
}

export function getItemsByList(list_id) {
    return request.post('./index.php', {
        function: 'getItemsByList',
        list_id
    })
}

export function getReviewsByTask(task_id) {
    return request.post('./index.php', {
        function: 'getReviewsByTask',
        task_id
    })
}

export function getMyCheckTasksToReview(my_id, review_status) {
    return request.post('./index.php', {
        function: 'getMyCheckTasksToReview',
        my_id,
        review_status
    })
}

export function getReviewById(id) {
    return request.post('./index.php', {
        function: 'getReviewById',
        id
    })
}

export function checkItem(reviewer_id, item_id, review_id) {
    return request.post('./index.php', {
        function: 'checkItem',
        reviewer_id,
        item_id,
        review_id
    })
}

export function changeReviewStatus(review_id, status) {
    return request.post('./index.php', {
        function: 'changeReviewStatus',
        review_id,
        status
    })
}

export function getMyCreatedCheckTasks(my_id) {
    return request.post('./index.php', {
        function: 'getMyCreatedCheckTasks',
        my_id
    })
}

export function getUserList(param) {
    return request.post('./index.php', {
        function: 'getUserList',
        ...param
    })
}

export function getUserListWithType(user_employment) {
    return request.post('./index.php', {
        function: 'getUserListWithType',
        user_employment
    })
}



export function getFulltimeUserList() {
    return request.post('./index.php', {
        function: 'getFulltimeUserList'
    })
}

export function getUserBUList() {
    return request.post('./index.php', {
        function: 'getUserBUList'
    })
}
export function updateUserBU(id, name) {
    return request.post('./index.php', {
        function: 'updateUserBU',
        id,
        name
    })
}

export function deleteUserBU(id) {
    return request.post('./index.php', {
        function: 'deleteUserBU',
        id
    })
}

export function getUserOKRList() {
    return request.post('./index.php', {
        function: 'getUserOKRList'
    })
}
export function getEmployeeOKRLists(employee_id) {
    return request.post('./index.php', {
        function: 'getEmployeeOKRLists',
        employee_id
    })
}

export function getEmployeeNewOKR(employee_id) {
    return request.post('./index.php', {
        function: 'getEmployeeNewOKR',
        employee_id
    })
}

export function updateUserOKR(okr_id, employee_id, content) {
    return request.post('./index.php', {
        function: 'updateUserOKR',
        okr_id,
        employee_id,
        content
    })
}

export function removeUserOKR(id) {
    return request.post('./index.php', {
        function: 'removeUserOKR',
        id
    })
}

export function getUser(employee_id) {
    return request.post('./index.php', {
        function: 'getUser',
        employee_id
    })
}

export function updateUser(
    account_id,
    employee_id,
    user_email,
    user_pwd,
    user_lastName,
    user_givenName,
    user_employment,
    wechat_work_id,
    employee_sheet_template_id,
    employee_department,
    employee_active_status,
    teammember_ids,
    bu_names,
    insurance,
    work_start_time,
    attendance_bonus_time,
    bank_id,
    isSmartAdmin,
    employee_management_territory,
    employee_management,
    atHomeWeek,
    user_country,
    user_city,
    employee_trainer,
    responsibility,
    user_enName,
    user_mobile
) {
    return request.post('./index.php', {
        function: 'updateUser',
        account_id,
        employee_id,
        user_email,
        user_pwd,
        user_lastName,
        user_givenName,
        user_employment,
        wechat_work_id,
        employee_sheet_template_id,
        employee_department,
        employee_active_status,
        teammember_ids,
        bu_names,
        insurance,
        work_start_time,
        attendance_bonus_time,
        bank_id,
        isSmartAdmin,
        employee_management_territory,
        employee_management,
        atHomeWeek,
        user_country,
        user_city,
        employee_trainer,
        responsibility,
        user_enName,
        user_mobile
    })
}

export function updateTerritory(employee_id, territorys, employee_territory, employee_territory_level, is_change) {
    return request.post('./index.php', {
        function: 'updateTerritory',
        employee_id,
        territorys,
        employee_territory,
        employee_territory_level,
        is_change
    })
}
export function removeTerritory(employee_id, territorys) {
    return request.post('./index.php', {
        function: 'updateTerritory',
        employee_id,
        territorys
    })
}

export function getClassList() {
    return request.post('./index.php', {
        function: 'getClassList'
    })
}

export function getClass(class_id) {
    return request.post('./index.php', {
        function: 'getClass',
        class_id
    })
}

// 员工学习课程列表
export function getMyassignedClasses(employee_id) {
    return request.post('./index.php', {
        function: 'getMyassignedClasses',
        employee_id
    })
}

// 新增/修改课程
export function updateClass(class_id, class_name, class_BU, class_description, class_avatar_url, session_completion_required) {
    return request.post('./index.php', {
        function: 'updateClass',
        class_id,
        class_name,
        class_BU,
        class_description,
        class_avatar_url,
        session_completion_required
    })
}

// 获取全部员工
export function getEmployees() {
    return request.post('./index.php', {
        function: 'getEmployees'
    })
}

// 员工分配
export function assignClassToEmployee(class_id, employee_id) {
    return request.post('./index.php', {
        function: 'assignClassToEmployee',
        class_id,
        employee_id
    })
}

export function getSession(session_id) {
    return request.post('./index.php', {
        function: 'getSession',
        session_id
    })
}
export function getContents(session_id, student_id) {
    return request.post('./index.php', {
        function: 'getContents',
        session_id,
        student_id
    })
}

export function updateSession(session_id, session_name, session_description, class_id) {
    return request.post('./index.php', {
        function: 'updateSession',
        session_id,
        session_name,
        session_description,
        class_id
    })
}

export function addSessionToClass(session_id, class_id) {
    return request.post('./index.php', {
        function: 'addSessionToClass',
        session_id,
        class_id
    })
}
export function addContentToSession(content_id, session_id) {
    return request.post('./index.php', {
        function: 'addContentToSession',
        content_id,
        session_id
    })
}

export function getContent(content_id, student_id) {
    return request.post('./index.php', {
        function: 'getContent',
        content_id,
        student_id
    })
}

export function getContentAdmin(content_id) {
    return request.post('./index.php', {
        function: 'getContentAdmin',
        content_id
    })
}

// export function getContent({
//     content_id,
//     student_id
//   }) {
//     return axios.post('./index.php', {
//         function: 'getContent',
//         content_id,
//         student_id
//       })
//       .then(function (response) {
//         return response.data
//       })
//       .catch(function (error) {
//         console.log(error);
//       })
//   }

export function updateContent(content_id, content_name, content_level, content_type, content_instruction, content_length, audio_url, video_url, pdf_url, html_text, class_id, session_id, workshop_ids) {
    return request.post('./index.php', {
        function: 'updateContent',
        content_id,
        content_name,
        content_level,
        content_type,
        content_instruction,
        content_length,
        audio_url,
        video_url,
        pdf_url,
        html_text,
        class_id,
        session_id,
        workshop_ids
    })
}
/**
 * 反馈管理模块
 */

// 查看下属
export function getTeammembers(id) {
    return request.post('./index.php', {
        function: 'getTeammembers',
        id
    })
}
// 问题列表
export function getIssues() {
    return request.post('./index.php', {
        function: 'getIssues'
    })
}
// 回复问题
export function replyIssue(id, reply) {
    return request.post('./index.php', {
        function: 'replyIssue',
        id,
        reply
    })
}
// 添加问题
export function updateIssue(id, sender_id, project_code, title) {
    return request.post('./index.php', {
        function: 'updateIssue',
        id,
        sender_id,
        project_code,
        title
    })
}
// 追踪反馈数量
export function getFeedbacks(id) {
    return request.post('./index.php', {
        function: 'getFeedbacks',
        id
    })
}

export function removeUser(id) {
    return request.post('./index.php', {
        function: 'removeUser',
        id
    })
}

export function getClassAssignedUsers(class_id) {
    return request.post('./index.php', {
        function: 'getClassAssignedUsers',
        class_id
    })
}

export function getClassUserProgress(class_id) {
    return request.post('./index.php', {
        function: 'getClassUserProgress',
        class_id
    })
}

export function getClassQuizStats(class_id) {
    return request.post('./index.php', {
        function: 'getClassQuizStats',
        class_id
    })
}

export function cancelAssigned(user_id, class_id) {
    return request.post('./index.php', {
        function: 'cancelAssigned',
        user_id,
        class_id
    })
}
export function removeSession(session_id, class_id) {
    return request.post('./index.php', {
        function: 'removeSession',
        session_id,
        class_id
    })
}
export function removeContentQuiz(content_id, quiz_id) {
    return request.post('./index.php', {
        function: 'removeContentQuiz',
        content_id,
        quiz_id
    })
}
// 我的下属--添加评论
export function updateFeedback(id, reviewee_id, reviewer_id, type, content) {
    return request.post('./index.php', {
        function: 'updateFeedback',
        id,
        reviewee_id,
        reviewer_id,
        type,
        content
    })
}

export function updateSessionOrderInClass(class_id, session_ids) {
    return request.post('./index.php', {
        function: 'updateSessionOrderInClass',
        class_id,
        session_ids
    })
}

export function updateContentOrderInSession(session_id, content_ids) {
    return request.post('./index.php', {
        function: 'updateContentOrderInSession',
        session_id,
        content_ids
    })
}

export function updateContentQuiz(id, content_id, question_title, question_answer, quiz_position, question_options, question_solution, question_test_point) {
    return request.post('./index.php', {
        function: 'updateContentQuiz',
        id,
        content_id,
        question_title,
        question_answer,
        quiz_position,
        question_options,
        question_solution,
        question_test_point
    })
}
export function getContentQuizes(content_id) {
    return request.post('./index.php', {
        function: 'getContentQuizes',
        content_id
    })
}
export function getQuiz(id) {
    return request.post('./index.php', {
        function: 'getQuiz',
        id
    })
}
export function uploadQuiz({
    quiz_id,
    user_id,
    quiz_answer
}) {
    return axios.post('./index.php', {
        function: 'uploadQuiz',
        quiz_id,
        user_id,
        quiz_answer
    })
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error)
        })
}
export function resetQuiz(
    user_id,
    class_id,
    session_id
) {
    return axios.post('./index.php', {
        function: 'resetQuiz',
        user_id,
        class_id,
        session_id
    })
        .then(function (response) {
            return response.data
        })
        .catch(function (error) {
            console.log(error)
        })
}

export function getITRequest(id) {
    return request.post('./index.php', {
        function: 'getITRequest',
        id
    })
}

export function solveITRequest(id, solver_id, status, note) {
    return request.post('./index.php', {
        function: 'solveITRequest',
        id,
        solver_id,
        status,
        note
    })
}

export function deleteITRequest(id) {
    return request.post('./index.php', {
        function: 'deleteITRequest',
        id
    })
}

export function getITRequests(status) {
    return request.post('./index.php', {
        function: 'getITRequests',
        status
    })
}

export function updateITRequest(id, user_id, demand, reason) {
    return request.post('./index.php', {
        function: 'updateITRequest',
        id,
        user_id,
        demand,
        reason
    })
}

export function updateITRequestStatus(id, status) {
    return request.post('./index.php', {
        function: 'updateITRequestStatus',
        id,
        status
    })
}

export function updateWorkshop(id, name, intro, template_id) {
    return request.post('./index.php', {
        function: 'updateWorkshop',
        id,
        name,
        intro,
        template_id
    })
}

export function getWorkshops() {
    return request.post('./index.php', {
        function: 'getWorkshops'
    })
}

export function getWorkshop(id) {
    return request.post('./index.php', {
        function: 'getWorkshop',
        id
    })
}

export function updateWorkshopEvaluationForm(id, workshop_id, evaluation_dimensions) {
    return request.post('./index.php', {
        function: 'updateWorkshopEvaluationForm',
        id,
        workshop_id,
        evaluation_dimensions
    })
}

export function getWorkshopEvaluationForm(id) {
    return request.post('./index.php', {
        function: 'getWorkshopEvaluationForm',
        id
    })
}

export function getSessions() {
    return request.post('./index.php', {
        function: 'getSessions'
    })
}

export function addContentWorkshop(workshop_id, content_id) {
    return request.post('./index.php', {
        function: 'addContentWorkshop',
        workshop_id,
        content_id
    })
}

export function deleteContentWorkshop(workshop_id, content_id) {
    return request.post('./index.php', {
        function: 'deleteContentWorkshop',
        workshop_id,
        content_id
    })
}

export function updateWorkshopEntry(id, workshop_id, user_id, contents) {
    return request.post('./index.php', {
        function: 'updateWorkshopEntry',
        id,
        workshop_id,
        user_id,
        contents
    })
}

export function getWorkshopEntry(id) {
    return request.post('./index.php', {
        function: 'getWorkshopEntry',
        id
    })
}

export function getWorkshopEntries(workshop_id, account_id) {
    return request.post('./index.php', {
        function: 'getWorkshopEntries',
        workshop_id,
        account_id
    })
}

export function updateWorkshopEntryCritique(id, entry_id, creater_id, content) {
    return request.post('./index.php', {
        function: 'updateWorkshopEntryCritique',
        id,
        entry_id,
        creater_id,
        content
    })
}

export function getWorkshopEntryCritique(id) {
    return request.post('./index.php', {
        function: 'getWorkshopEntryCritique',
        id
    })
}

export function getWorkshopEntryCritiques(entry_id) {
    return request.post('./index.php', {
        function: 'getWorkshopEntryCritiques',
        entry_id
    })
}

export function updateWorkshopEntryEvaluation(id, entry_id, form_id, creater_id, contents) {
    return request.post('./index.php', {
        function: 'updateWorkshopEntryEvaluation',
        id,
        entry_id,
        form_id,
        creater_id,
        contents
    })
}

export function getWorkshopEntryEvaluation(id) {
    return request.post('./index.php', {
        function: 'getWorkshopEntryEvaluation',
        id
    })
}

export function getWorkshopTemplates() {
    return request.post('./index.php', {
        function: 'getWorkshopTemplates'
    })
}

export function getWorkshopTemplate(id) {
    return request.post('./index.php', {
        function: 'getWorkshopTemplate',
        id
    })
}
export function updateWorkshopTemplate(id, name, dimensions) {
    return request.post('./index.php', {
        function: 'updateWorkshopTemplate',
        id,
        name,
        dimensions
    })
}

export function getSessionWorkShop(session_id) {
    return request.post('./index.php', {
        function: 'getSessionWorkShop',
        session_id
    })
}

export function getContentWorkShop(content_id, user_id) {
    return request.post('./index.php', {
        function: 'getContentWorkShop',
        content_id,
        user_id
    })
}

export function getWorkshopEntryByUser(workshop_id, user_id) {
    return request.post('./index.php', {
        function: 'getWorkshopEntryByUser',
        workshop_id,
        user_id
    })
}

export function getWorkingSchedule(number, user_id) {
    return request.post('./index.php', {
        function: 'getWorkingSchedule',
        number,
        user_id
    })
}

export function getTemplateByEmployee(user_id) {
    return request.post('./index.php', {
        function: 'getTemplateByEmployee',
        user_id
    })
}

export function getTimeSheetById(id) {
    return request.post('./index.php', {
        function: 'getTimeSheetById',
        id
    })
}

export function getLastTomorrowPlan(start_time, employee_id) {
    return request.post('./index.php', {
        function: 'getLastTomorrowPlan',
        start_time,
        employee_id
    })
}
export function updateWorkLog(user_id, sheet_id, work_time, real_params) {
    return request.post('./index.php', {
        function: 'updateWorkLog',
        user_id,
        sheet_id,
        work_time,
        real_params
    })
}

export function getMonthWorkingSchedule(user_id, year) {
    return request.post('./index.php', {
        function: 'getMonthWorkingSchedule',
        user_id,
        year
    })
}

export function updateMonthLog(
    user_id,
    month,
    year,
    month_content,
    month_get,
    month_analysis,
    month_value_boost,
    month_problem,
    month_suggest,
    month_plan,
    month_goal,
    month_need,
    month_time_assigned,
    month_extra_10,
    month_upward_feedback,
    month_new_discovery,
    month_technology_administration,
    month_meeting_administration,
    month_feedback_administration,
    month_standard_administration,
    month_skill_upgrading
) {
    return request.post('./index.php', {
        function: 'updateMonthLog',
        user_id,
        month,
        year,
        month_content,
        month_get,
        month_analysis,
        month_value_boost,
        month_problem,
        month_suggest,
        month_plan,
        month_goal,
        month_need,
        month_time_assigned,
        month_extra_10,
        month_upward_feedback,
        month_new_discovery,
        month_technology_administration,
        month_meeting_administration,
        month_feedback_administration,
        month_standard_administration,
        month_skill_upgrading
    })
}

export function getMonthTimeSheetById(id) {
    return request.post('./index.php', {
        function: 'getMonthTimeSheetById',
        id
    })
}

export function getWorkLogTemplates() {
    return request.post('./index.php', {
        function: 'getWorkLogTemplates'
    })
}

export function getWorkLogTemplate(id) {
    return request.post('./index.php', {
        function: 'getWorkLogTemplate',
        id
    })
}

export function updateWorkLogTemplate(id, template_name, template_parameters_array, template_required_array) {
    return request.post('./index.php', {
        function: 'updateWorkLogTemplate',
        id,
        template_name,
        template_parameters_array,
        template_required_array
    })
}

export function addSuperviserToUser(user_id, superviser_id) {
    return request.post('./index.php', {
        function: 'addSuperviserToUser',
        user_id,
        superviser_id
    })
}
export function removeSuperviserFromUser(user_id, superviser_id) {
    return request.post('./index.php', {
        function: 'removeSuperviserFromUser',
        user_id,
        superviser_id
    })
}

export function getTimeSheetsByUser(user_id) {
    return request.post('./index.php', {
        function: 'getTimeSheetsByUser',
        user_id
    })
}

export function updateETATodo(id, user_id, wechat, article_name, content, assigned_user_ids, project, article_address, refer, format, type, date) {
    return request.post('./index.php', {
        function: 'updateETATodo',
        id,
        user_id,
        wechat,
        article_name,
        content,
        assigned_user_ids,
        project,
        article_address,
        refer,
        format,
        type,
        date
    })
}

export function getTodoSubs(todo_id, type) {
    return request.post('./index.php', {
        function: 'getTodoSubs',
        todo_id,
        type
    })
}

export function getTodoStandards() {
    return request.post('./index.php', {
        function: 'getTodoStandards'
    })
}

export function updateTodoStandard(standard_id, format, type, content, valid_status, note, priority) {
    return request.post('./index.php', {
        function: 'updateTodoStandard',
        standard_id,
        format,
        type,
        content,
        valid_status,
        note,
        priority
    })
}

export function getTodoTypes() {
    return request.post('./index.php', {
        function: 'getTodoTypes'
    })
}

export function updateTodoType(type_id, format, type, note) {
    return request.post('./index.php', {
        function: 'updateTodoType',
        type_id,
        format,
        type,
        note
    })
}

export function getTodoTypesByFormat(format) {
    return request.post('./index.php', {
        function: 'getTodoTypesByFormat',
        format
    })
}

export function getTeamRecords() {
    return request.post('./index.php', {
        function: 'getTeamRecords'

    })
}

export function updateTodoSub(todo_id, type, content, note, format, priority) {
    return request.post('./index.php', {
        function: 'updateTodoSub',
        todo_id,
        type,
        content,
        note,
        format,
        priority
    })
}
export function updateTodoSubName(sub_id, content) {
    return request.post('./index.php', {
        function: 'updateTodoSubName',
        sub_id,
        content
    })
}

export function updateTodoSubFinishStatus(id, reviewer_id) {
    return request.post('./index.php', {
        function: 'updateTodoSubFinishStatus',
        id,
        reviewer_id
    })
}

export function updateTodoSubRequireStatus(id) {
    return request.post('./index.php', {
        function: 'updateTodoSubRequireStatus',
        id
    })
}

export function refreshStandardSubList(todo_id) {
    return request.post('./index.php', {
        function: 'refreshStandardSubList',
        todo_id
    })
}

export function updateTodoSubUserIDs(id, user_ids) {
    return request.post('./index.php', {
        function: 'updateTodoSubUserIDs',
        id,
        user_ids
    })
}
export function getAllTodos(project, status, format, type, user_id) {
    return request.post('./index.php', {
        function: 'getAllTodos',
        project,
        status,
        format,
        user_id
    })
}

export function getHistoryTODOs(todo_id) {
    return request.post('./index.php', {
        function: 'getHistoryTODOs',
        todo_id
    })
}

export function deleteProjectTODO(todo_id) {
    return request.post('./index.php', {
        function: 'deleteProjectTODO',
        todo_id

    })
}

export function getCompleteTodos() {
    return request.post('./index.php', {
        function: 'getCompleteTodos'

    })
}

export function getPublishTodos(user_id, project, format) {
    return request.post('./index.php', {
        function: 'getPublishTodosNew',
        user_id,
        project,
        format

    })
}

export function getDesignTodos(user_id) {
    return request.post('./index.php', {
        function: 'getDesignTodos',
        user_id

    })
}

export function getPriorityTodos(user_id) {
    return request.post('./index.php', {
        function: 'getPriorityTodos',
        user_id

    })
}

export function getMyAssignedTodos(project, status, format, type, my_user_id) {
    return request.post('./index.php', {
        function: 'getMyAssignedTodos',
        project,
        status,
        format,
        my_user_id
    })
}
export function getMySubs(user_id) {
    return request.post('./index.php', {
        function: 'getMySubs',
        user_id
    })
}

export function getMyAssignedTodo(user_id) {
    return request.post('./index.php', {
        function: 'getMyAssignedTodo',
        user_id
    })
}

export function changeTodoStatus(user_id, todo_id, status) {
    return request.post('./index.php', {
        function: 'changeTodoStatus',
        user_id,
        todo_id,
        status
    })
}

export function getAllTODOProjects() {
    return request.post('./index.php', {
        function: 'getAllTODOProjects'
    })
}

export function getWorkingLogTable(number) {
    return request.post('./index.php', {
        function: 'getWorkingLogTable',
        number
    })
}

export function getUserType(user_id) {
    return request.post('./index.php', {
        function: 'getUserType',
        user_id
    })
}
export function userPerformanceStatistic(start_time, end_time) {
    return request.post('./index.php', {
        function: 'userPerformanceStatistic',
        start_time,
        end_time
    })
}

export function getMonthWorkingTable(year) {
    return request.post('./index.php', {
        function: 'getMonthWorkingTable',
        year
    })
}

export function getStandards() {
    return request.post('./index.php', {
        function: 'getStandards'
    })
}

export function getStandardEmployee(standard_id) {
    return request.post('./index.php', {
        function: 'getStandardEmployee',
        standard_id
    })
}
export function getStandard(standard_id, user_id) {
    return request.post('./index.php', {
        function: 'getStandard',
        standard_id,
        user_id
    })
}
export function checkStandard(standard_id, version, user_id) {
    return request.post('./index.php', {
        function: 'checkStandard',
        standard_id,
        version,
        user_id
    })
}

export function getUserStandards(user_id) {
    return request.post('./index.php', {
        function: 'getUserStandards',
        user_id
    })
}

export function updateStandard(standard_id, standard_business, standard_name, standard_description, standard_department, version, update_note, is_open) {
    return request.post('./index.php', {
        function: 'updateStandard',
        standard_id,
        standard_business,
        standard_name,
        standard_description,
        standard_department,
        version,
        update_note,
        is_open
    })
}

export function getFeedbacksAdmin() {
    return request.post('./index.php', {
        function: 'getFeedbacksAdmin'
    })
}

// exam admin 部分
export function createExerciseTemplate(template_id, template_name = '', test_subject = '', template_instruction = '', test_duration = '', test_type = '', test_BU = '', test_time = '', test_refer = '') {
    return request.post('./index.php', {
        function: 'createExerciseTemplate',
        template_id,
        template_name,
        test_subject,
        template_instruction,
        test_duration,
        test_type,
        test_BU,
        test_time,
        test_refer
    })
}
export function updateExerciseTemplate(template_id, template_name = '', test_subject = '', template_instruction = '', test_duration = '', test_type = '', test_BU = '', test_time = '', test_refer = '') {
    return request.post('./index.php', {
        function: 'updateExerciseTemplate',
        template_id,
        template_name,
        test_subject,
        template_instruction,
        test_duration,
        test_type,
        test_BU,
        test_time,
        test_refer
    })
}
export function removeExerciseTemplate(template_id) {
    return request.post('./index.php', {
        function: 'removeExerciseTemplate',
        template_id
    })
}
export function addExerciseQuestionToTemplateSection(question_id, section_id) {
    return request.post('./index.php', {
        function: 'addExerciseQuestionToTemplateSection',
        question_id,
        section_id
    })
}
export function removeExerciseQuestionInTemplateSection(question_id, section_id) {
    return request.post('./index.php', {
        function: 'removeExerciseQuestionInTemplateSection',
        question_id,
        section_id
    })
}
export function updateExerciseQuestion(
    question_id,
    question_subject,
    question_year,
    question_level,
    question_competency,
    question_question,
    question_optionA,
    question_optionB,
    question_optionC,
    question_optionD,
    question_optionE,
    question_answer
) {
    return request.post('./index.php', {
        function: 'updateExerciseQuestion',
        question_id,
        question_subject,
        question_year,
        question_level,
        question_competency,
        question_question,
        question_optionA,
        question_optionB,
        question_optionC,
        question_optionD,
        question_optionE,
        question_answer
    })
}
export function getExerciseQuestion(question_id) {
    return request.post('./index.php', {
        function: 'getExerciseQuestion',
        question_id
    })
}
export function getExerciseQuestions() {
    return request.post('./index.php', {
        function: 'getExerciseQuestions'
    })
}
export function createExerciseTest(template_id, test_taker_id) {
    return request.post('./index.php', {
        function: 'createExerciseTest',
        template_id,
        test_taker_id
    })
}
export function getExerciseTests() {
    return request.post('./index.php', {
        function: 'getExerciseTests'
    })
}
export function getExerciseTest(test_id) {
    return request.post('./index.php', {
        function: 'getExerciseTest',
        test_id
    })
}

export function findStudentByEmail(email) {
    return request.post('./index.php', {
        function: 'findStudentByEmail',
        email
    })
}

export function getExerciseTemplates() {
    return request.post('./index.php', {
        function: 'getExerciseTemplates'
    })
}
export function getExerciseTemplate(template_id) {
    return request.post('./index.php', {
        function: 'getExerciseTemplate',
        template_id
    })
}

export function resortQuestionsInTemplate(template_id, questions) {
    return request.post('./index.php', {
        function: 'resortQuestionsInTemplate',
        template_id,
        questions
    })
}

export function uploadBanner(params) {
    return request.post('./index.php', params)
}

export function updateExerciseTemplateSection(section_id, template_id, section_title, section_instruction, section_material) {
    return request.post('./index.php', {
        function: 'updateExerciseTemplateSection',
        section_id,
        template_id,
        section_title,
        section_instruction,
        section_material
    })
}

export function removeExerciseSection(section_id, template_id) {
    return request.post('./index.php', {
        function: 'removeExerciseSection',
        section_id,
        template_id
    })
}

export function getExerciseSection(section_id) {
    return request.post('./index.php', {
        function: 'getExerciseSection',
        section_id
    })
}

export function getExerciseQuestionsByName(search_word) {
    return request.post('./index.php', {
        function: 'getExerciseQuestionsByName',
        search_word
    })
}

export function resortQuestionsInTemplateSection(section_id, questions) {
    return request.post('./index.php', {
        function: 'resortQuestionsInTemplateSection',
        section_id,
        questions
    })
}
export function importCsv(param) {
    const paramInfo = Object.assign({}, param, { function: 'importCsv' })
    return request.post('./index.php', paramInfo)
}
export function importCsvInTemplate(param) {
    const paramInfo = Object.assign({}, param, { function: 'importCsvInTemplate' })
    return request.post('./index.php', paramInfo)
}
export function BatchDelExerciseQuestionInTemplateSection(param) {
    const paramInfo = Object.assign({}, param, { function: 'BatchDelExerciseQuestionInTemplateSection' })
    return request.post('./index.php', paramInfo)
}

/// exam web

export function getExerciseTemplatesByStudent(student_id) {
    return request.post('./index.php', {
        function: 'getExerciseTemplatesByStudent',
        student_id
    })
}

export function submitAnswer(question_id, template_id, choice, student_id) {
    return request.post('./index.php', {
        function: 'submitAnswer',
        question_id,
        template_id,
        choice,
        student_id
    })
}
export function uploadAnswers(answers, student_id, template_id) {
    return request.post('./index.php', {
        function: 'uploadAnswers',
        answers,
        student_id,
        template_id
    })
}
export function loginByStudent(student_name, student_pwd) {
    return request.post('./index.php', {
        function: 'loginByStudent',
        student_name,
        student_pwd
    })
}

export function changeVisibleLog(student_id, template_id) {
    return request.post('./index.php', {
        function: 'changeVisibleLog',
        student_id,
        template_id
    })
}
export function getTestVisibleChangeCount(student_id, template_id) {
    return request.post('./index.php', {
        function: 'getTestVisibleChangeCount',
        student_id,
        template_id
    })
}

export function getExerciseTemplateByStudentReview(student_id, template_id) {
    return request.post('./index.php', {
        function: 'getExerciseTemplateByStudentReview',
        student_id,
        template_id
    })
}

export function getExerciseTemplateByStudent(template_id) {
    return request.post('./index.php', {
        function: 'getExerciseTemplateByStudent',
        template_id
    })
}

export function getEvaluationFormByWorkshop(workshop_id) {
    return request.post('./index.php', {
        function: 'getEvaluationFormByWorkshop',
        workshop_id
    })
}

export function getWorkshopEntryEvaluations(entry_id) {
    return request.post('./index.php', {
        function: 'getWorkshopEntryEvaluations',
        entry_id
    })
}

export function getUserLastMonthFee(user_id) {
    return request.post('./index.php', {
        function: 'getUserLastMonthFee',
        user_id
    })
}

export function updateBank(bank_id, bank_name) {
    return request.post('./index.php', {
        function: 'updateBank',
        bank_id,
        bank_name
    })
}
export function getBanks() {
    return request.post('./index.php', {
        function: 'getBanks'
    })
}

export function getMyHolidayAppliesByStatus(user_id, status) {
    return request.post('./index.php', {
        function: 'getMyHolidayAppliesByStatus',
        user_id,
        status
    })
}

export function applyHoliday(id, user_id, type, details, pic_arr, time, hour_len, check_users) {
    return request.post('./index.php', {
        function: 'applyHoliday',
        id,
        user_id,
        type,
        details,
        pic_arr,
        time,
        hour_len,
        check_users
    })
}

export function checkApply(id, checker_id, status, check_note) {
    return request.post('./index.php', {
        function: 'checkApply',
        id,
        checker_id,
        status,
        check_note
    })
}

export function getHolidayAppliesByStatus(status, checker_id) {
    return request.post('./index.php', {
        function: 'getHolidayAppliesByStatus',
        status,
        checker_id
    })
}

export function cancelHolidayApply(apply_id) {
    return request.post('./index.php', {
        function: 'cancelHolidayApply',
        apply_id
    })
}

export function uploadApplyPic(param) {
    return request.post('./index.php', param)
}

export function updateUserHoliday(user_id, lieu_holiday, year_holiday, special_holiday) {
    return request.post('./index.php', {
        function: 'updateUserHoliday',
        user_id,
        lieu_holiday,
        year_holiday,
        special_holiday
    })
}

export function checkUserAppliedHolidays(user_id) {
    return request.post('./index.php', {
        function: 'checkUserAppliedHolidays',
        user_id
    })
}

export function getQuarterWorkingSchedule(year) {
    return request.post('./index.php', {
        function: 'getQuarterWorkingSchedule',
        year
    })
}

export function getQuarterTimeSheet(quarter, year, user_id) {
    return request.post('./index.php', {
        function: 'getQuarterTimeSheet',
        quarter,
        year,
        user_id
    })
}

export function updateQuarterLog(quarter, year, review, point, user_id) {
    return request.post('./index.php', {
        function: 'updateQuarterLog',
        quarter,
        year,
        review,
        point,
        user_id
    })
}

export function getRegulations(param) {
    return request.post('./index.php', {
        function: 'getRegulations',
        ...param
    })
}
export function getRegulationsUser(user_id) {
    return request.post('./index.php', {
        function: 'getRegulationsUser',
        user_id
    })
}

export function updateRegulation(param) {
    return request.post('./index.php', {
        function: 'updateRegulation',
        ...param
    })
}
export function removeRegulation(id) {
    return request.post('./index.php', {
        function: 'removeRegulation',
        id
    })
}

export function getRegulation(id, user_id) {
    return request.post('./index.php', {
        function: 'getRegulation',
        id,
        user_id
    })
}

export function checkRegulation(id, version, user_id, disagreeInfo, check_id, agree) {
    return request.post('./index.php', {
        function: 'checkRegulation',
        id,
        version,
        user_id,
        disagreeInfo,
        check_id,
        agree
    })
}

export function checkRegulationDisagree(id, version, user_id, disagreeInfo) {
    return request.post('./index.php', {
        function: 'checkRegulationDisagree',
        id,
        version,
        user_id,
        disagreeInfo
    })
}

export function getQuarterWorkingScheduleByUser(year, user_id) {
    return request.post('./index.php', {
        function: 'getQuarterWorkingScheduleByUser',
        year,
        user_id
    })
}

export function checkUserMissingLog(user_id) {
    return request.post('./index.php', {
        function: 'checkUserMissingLog',
        user_id
    })
}

export function checkLastWorkShopFinishStatus(user_id, session_id, content_id) {
    return request.post('./index.php', {
        function: 'checkLastWorkShopFinishStatus',
        user_id,
        session_id,
        content_id
    })
}

export function getLastMonth() {
    return request.post('./index.php', {
        function: 'getLastMonth'
    })
}

export function removeContent(content_id, session_id) {
    return request.post('./index.php', {
        function: 'removeContent',
        content_id,
        session_id
    })
}

export function removeEvaluation(id) {
    return request.post('./index.php', {
        function: 'removeEvaluation',
        id
    })
}

export function removeCritique(id) {
    return request.post('./index.php', {
        function: 'removeCritique',
        id
    })
}

export function removeWorkshopTemplate(id) {
    return request.post('./index.php', {
        function: 'removeWorkshopTemplate',
        id
    })
}

export function removeWorkshop(id) {
    return request.post('./index.php', {
        function: 'removeWorkshop',
        id
    })
}

export function getSpeechArts() {
    return request.post('./index.php', {
        function: 'getSpeechArts'
    })
}

export function updateSpeechArt(id, question, answer, reason, labels, user_id) {
    return request.post('./index.php', {
        function: 'updateSpeechArt',
        id,
        question,
        answer,
        reason,
        labels,
        user_id
    })
}

export function removetSpeechArt(id) {
    return request.post('./index.php', {
        function: 'removetSpeechArt',
        id
    })
}

export function changeProjectTodoFastStatus(status, todo_id, user_id) {
    return request.post('./index.php', {
        function: 'changeProjectTodoFastStatus',
        status,
        todo_id,
        user_id
    })
}
export function changeProjectTodoStarStatus(status, todo_id, user_id) {
    return request.post('./index.php', {
        function: 'changeProjectTodoStarStatus',
        status,
        todo_id,
        user_id
    })
}

export function getCorrectionSampleTypes(category) {
    return request.post('./index.php', {
        function: 'getCorrectionSampleTypes',
        category

    })
}

export function getCorrectionDomains() {
    return request.post('./index.php', {
        function: 'getCorrectionDomains'

    })
}

export function getCorrectionSamples() {
    return request.post('./index.php', {
        function: 'getCorrectionSamples'

    })
}

export function updateCorrectionSample(sample_id, problem, answer, type, note, domain, pic_url, expression_type, problem_audio_url_list, answer_audio_url_list, note_audio_url_list, train_mode) {
    return request.post('./index.php', {
        function: 'updateCorrectionSample',
        sample_id,
        problem,
        answer,
        type,
        note,
        domain,
        pic_url,
        expression_type,
        problem_audio_url_list,
        answer_audio_url_list,
        note_audio_url_list,
        train_mode
    })
}

export function getCorrections(user_id) {
    return request.post('./index.php', {
        function: 'getCorrections',
        user_id
    })
}

export function removeCorrectionSample(id) {
    return request.post('./index.php', {
        function: 'removeCorrectionSample',
        id

    })
}

export function finishCorrection(user_id, sample_id, work) {
    return request.post('./index.php', {
        function: 'finishCorrection',
        user_id,
        sample_id,
        work
    })
}

export function getFinishTODORecord(user_id, day_time) {
    return request.post('./index.php', {
        function: 'getFinishTODORecord',
        user_id,
        day_time
    })
}

export function getUserFinishProjectTodoSubs(user_id, day_time) {
    return request.post('./index.php', {
        function: 'getUserFinishProjectTodoSubs',
        user_id,
        day_time
    })
}

export function getProjectTodoAttaches(todo_id) {
    return request.post('./index.php', {
        function: 'getProjectTodoAttaches',
        todo_id
    })
}

export function addProjectTodoAttach(todo_id, name, attach_key, user_id) {
    return request.post('./index.php', {
        function: 'addProjectTodoAttach',
        todo_id,
        name,
        attach_key,
        user_id
    })
}

export function removeProjectTodoAttach(id) {
    return request.post('./index.php', {
        function: 'removeProjectTodoAttach',
        id
    })
}

export function getQiniuToken() {
    return request.post('./index.php', {
        function: 'getQiniuToken'
    })
}

export function getSingaporeQiniuToken() {
    return request.post('./index.php', {
        function: 'getSingaporeQiniuToken'
    })
}

export function getBrands() {
    return request.post('./index.php', {
        function: 'getBrands'
    })
}

export function updateBrand(params) {
    return request.post('./index.php', {
        function: 'updateBrand',
        brand_id: params.brand_id,
        group: params.group,
        brand: params.brand,
        attach_key: params.attach_key,
        attach_name: params.attach_name,
        logo: params.logo,
        show_in_ReachPlus: params.show_in_ReachPlus,
        show_in_Account: params.show_in_Account,
        website: params.website,
        intro: params.intro,
        type: params.type,
        full_name: params.full_name
    })
}

export function getTips() {
    return request.post('./index.php', {
        function: 'getTips'
    })
}

export function updateTip(tip_id, department, category, content) {
    return request.post('./index.php', {
        function: 'updateTip',
        tip_id,
        department,
        category,
        content
    })
}

export function uploadPic(param) {
    return request.post('./index.php', param)
}

export function updateTodoDesignSourcer(todo_id, user_id, design_sourcer_name, design_sourcer_price, design_sourcer_target_finish_date, design_sourcer_status, design_sourcer_payment_status, design_sourcer_transaction_id) {
    return request.post('./index.php', {
        function: 'updateTodoDesignSourcer',
        todo_id,
        user_id,
        design_sourcer_name,
        design_sourcer_price,
        design_sourcer_target_finish_date,
        design_sourcer_status,
        design_sourcer_payment_status,
        design_sourcer_transaction_id
    })
}

export function removeRemoteFile(key) {
    return request.post('./index.php', {
        function: 'removeRemoteFile',
        key
    })
}

export function removeStandard(id) {
    return request.post('./index.php', {
        function: 'removeStandard',
        id
    })
}

export function updateSubRemark(id, content, user_id) {
    return request.post('./index.php', {
        function: 'updateSubRemark',
        id,
        content,
        user_id
    })
}

export function updateSubPoints(id, point, user_id) {
    return request.post('./index.php', {
        function: 'updateSubPoints',
        id,
        point,
        user_id
    })
}

export function updateTodoLevel(id, level) {
    return request.post('./index.php', {
        function: 'updateTodoLevel',
        id,
        level
    })
}

export function refreshUserPwd(user_id) {
    return request.post('./index.php', {
        function: 'refreshUserPwd',
        user_id
    })
}

export function updateNewUserPwd(user_id, new_pwd) {
    return request.post('./index.php', {
        function: 'updateNewUserPwd',
        user_id,
        new_pwd
    })
}

export function sendRemindMessageToSubTodoUsers(user_id, todo_content, sub_content, user_ids) {
    return request.post('./index.php', {
        function: 'sendRemindMessageToSubTodoUsers',
        user_id,
        todo_content,
        sub_content,
        user_ids
    })
}

export function updateSubDate(id, date) {
    return request.post('./index.php', {
        function: 'updateSubDate',
        id,
        date
    })
}

export function getSpecialWorkDay() {
    return request.post('./index.php', {
        function: 'getSpecialWorkDay'
    })
}

export function addSpecialWorkDay(time, timeType) {
    return request.post('./index.php', {
        function: 'addSpecialWorkDay',
        time,
        timeType
    })
}

export function removeSpecialWorkDay(day_id) {
    return request.post('./index.php', {
        function: 'removeSpecialWorkDay',
        day_id
    })
}

export function getUserWorkStartTimeList() {
    return request.post('./index.php', {
        function: 'getUserWorkStartTimeList'
    })
}

export function addUserEquipment(equipment_time, equipment_name, user_id) {
    return request.post('./index.php', {
        function: 'addUserEquipment',
        equipment_time,
        equipment_name,
        user_id
    })
}

export function removeUserEquipment(id) {
    return request.post('./index.php', {
        function: 'removeUserEquipment',
        id
    })
}

export function getUserEquipments(id) {
    return request.post('./index.php', {
        function: 'getUserEquipments',
        id
    })
}

export function sendAllEmployeeMessage(message) {
    return request.post('./index.php', {
        function: 'sendAllEmployeeMessage',
        message
    })
}

export function getAssistantDefaultData() {
    return request.post('./index.php', {
        function: 'getAssistantDefaultData'
    })
}

export function getUserMonthly(user_id) {
    return request.post('./index.php', {
        function: 'getUserMonthly',
        user_id
    })
}

export function hideList(list_id) {
    return request.post('./index.php', {
        function: 'hideList',
        list_id
    })
}

export function getNavUserStandardsCheck(user_id) {
    return request.post('./index.php', {
        function: 'getNavUserStandardsCheck',
        user_id
    })
}

/// Music Note

export function updateMusicNote(id, song_name, album_name, singer_name, type, note) {
    return request.post('./index.php', {
        function: 'updateMusicNote',
        id,
        song_name,
        album_name,
        singer_name,
        type,
        note
    })
}
export function getMyMusicNotes() {
    return request.post('./index.php', {
        function: 'getMyMusicNotes'

    })
}
export function searchMustNote(keyword) {
    return request.post('./index.php', {
        function: 'searchMusicNote',
        keyword
    })
}
export function checkWordingCorrection(user_id) {
    return request.post('./index.php', {
        function: 'checkUserCorrections',
        user_id

    })
}

export function checkUserAudioNotes(user_id) {
    return request.post('./index.php', {
        function: 'checkUserAudioNotes',
        user_id

    })
}

/// Game Note

export function updateGameNote(id, game_name, producer_name, type, note, aspect, platform) {
    return request.post('./index.php', {
        function: 'updateGameNote',
        id,
        game_name,
        producer_name,
        type,
        note,
        aspect,
        platform
    })
}
export function getMyGameNotes() {
    return request.post('./index.php', {
        function: 'getMyGameNotes'

    })
}
export function searchGameNote(keyword) {
    return request.post('./index.php', {
        function: 'searchGameNote',
        keyword
    })
}

export function updateUserHeadPic(employee_id, headPicUrl) {
    return request.post('./index.php', {
        function: 'updateUserHeadPic',
        employee_id,
        headPicUrl
    })
}

export function addUserMonth(year, month, user_id) {
    return request.post('./index.php', {
        function: 'addUserMonth',
        year,
        month,
        user_id
    })
}

export function getCollectionCompeletes() {
    return request.post('./index.php', {
        function: 'getCollectionCompeletes'
    })
}

export function scoreCompelete(compelete_id, score) {
    return request.post('./index.php', {
        function: 'scoreCompelete',
        compelete_id,
        score
    })
}

export function uploadAudio(param) {
    return request.post('./index.php', param)
}

export function commentCompelete(compelete_id, comment) {
    return request.post('./index.php', {
        function: 'commentCompelete',
        compelete_id,
        comment
    })
}
export function getBrandPolicys(brand_id) {
    return request.post('./index.php', {
        function: 'getBrandPolicys',
        brand_id
    })
}
export function updateBrandPolicy(policy_id, brand_id, content) {
    return request.post('./index.php', {
        function: 'updateBrandPolicy',
        policy_id,
        brand_id,
        content
    })
}

export function removeBrandPolicy(policy_id) {
    return request.post('./index.php', {
        function: 'removeBrandPolicy',
        policy_id
    })
}

export function audioRecord(user_id, sample_id, type, length) {
    return request.post('./index.php', {
        function: 'audioRecord',
        user_id,
        sample_id,
        type,
        length
    })
}

export function viewAudioRecord(sample_id, user_id) {
    return request.post('./index.php', {
        function: 'viewAudioRecord',
        sample_id,
        user_id
    })
}

export function loginByAccount(admin_email, admin_pwd) {
    return request.post('./index.php', {
        function: 'loginByAccount',
        admin_email,
        admin_pwd
    })
}
export function removeSubTODO(todo_id, index) {
    return request.post('./index.php', {
        function: 'removeSubTODO',
        todo_id,
        index
    })
}
export function changeTodoList(todo_id, list_id) {
    return request.post('./index.php', {
        function: 'changeTodoList',
        todo_id,
        list_id
    })
}

export function getEmployeeContract(id) {
    return request.post('./index.php', {
        function: 'getEmployeeContract',
        id
    })
}

export function addEmployeeContract(contract_id, user_id, contract_name, begin_time, end_time, signature_time, file_url) {
    return request.post('./index.php', {
        function: 'addEmployeeContract',
        contract_id,
        user_id,
        contract_name,
        begin_time,
        end_time,
        signature_time,
        file_url
    })
}

export function removeContract(id) {
    return request.post('./index.php', {
        function: 'removeContract',
        id
    })
}
export function getProjectTimePoints(select_project_code, point_time_type) {
    return request.post('./index.php', {
        function: 'getProjectTimePoints',
        select_project_code,
        point_time_type
    })
}

export function getProjectsByProjectYear(project_year) {
    return request.post('./index.php', {
        function: 'getProjectsByProjectYear',
        project_year
    })
}

export function updateProjectTimePoint(params) {
    return request.post('./index.php', {
        function: 'updateProjectTimePoint',
        id: params.id,
        project_code: params.project_code,
        content: params.content,
        operator_user_id: params.operator_user_id,
        time_point_date: params.time_point_date,
        persist_days: params.persist_days
    })
}

export function removeTimePoint(id) {
    return request.post('./index.php', {
        function: 'removeTimePoint',
        id
    })
}

export function sorSubTodo(todo_id, old_index, new_index) {
    return request.post('./index.php', {
        function: 'sorSubTodo',
        todo_id,
        old_index,
        new_index
    })
}

export function sendTodoRemind(param) {
    return request.post('./index.php', {
        function: 'sendTodoRemind',
        ...param
    })
}
export function sendSubTodoRemind(param) {
    return request.post('./index.php', {
        function: 'sendSubTodoRemind',
        ...param
    })
}

export function updateSubTODO1(param) {
    return request.post('./index.php', {
        function: 'updateSubTODO1',
        ...param
    })
}

export const sendMessage = (params) => request.post('./index.php', {
    ...params,
    function: 'sendReachMessage'
})

export const getChatList = (params) => request.post('./index.php', {
    ...params,
    function: 'getContactReachAllGroupChatList'
})

export const getChatObject = (params) => request.post('./index.php', {
    ...params,
    function: 'getChatObjectList'
})

export const createChatGroup = (params) => request.post('./index.php', {
    ...params,
    function: 'createContactReachGroup'
})

export const getMessageDetail = (params) => request.post('./index.php', {
    ...params,
    function: 'getContactReachMessages'
})

export const joinReachGroup = (params) => request.post('./index.php', {
    ...params,
    function: 'joinContactReachGroup'
})

export const getContactList = (params) => request.post('./index.php', {
    ...params,
    function: 'getContactList'
})

export const getContactWithEmail = (params) => request.post('./index.php', {
    ...params,
    function: 'getContactWithEmail'
})

export const getReachChatProjects = (params) => request.post('./index.php', {
    ...params,
    function: 'getReachChatProjects'
})

export const getUserConversationList = (params) => request.post('./index.php', {
    ...params,
    function: 'getUserConversationList'
})
export const getUserConversationListTen = (params) => request.post('./index.php', {
    ...params,
    function: 'getUserConversationListTen'
})
export const updateConversation = (params) => request.post('./index.php', {
    ...params,
    function: 'updateConversation'
})
export const removeConversation = (params) => request.post('./index.php', {
    ...params,
    function: 'removeConversation'
})
export const getReachChatObjects = (params) => request.post('./index.php', {
    ...params,
    function: 'getReachChatObjects'
})

export const removeContactReachGroup = (params) => request.post('./index.php', {
    ...params,
    function: 'removeContactReachGroup'
})

export const getContactReachGroupDetail = (params) => request.post('./index.php', {
    ...params,
    function: 'getContactReachGroupDetail'
})

export const updateConversationCoordinator = (params) => request.post('./index.php', {
    ...params,
    function: 'updateConversationCoordinator'
})

export const sendConversation = (params) => request.post('/AIChat/index.php', {
    ...params,
    function: 'sendConversation'
})

export const initConversationName = (params) => request.post('./index.php', {
    ...params,
    function: 'initConversationName'
})

export const sendConversationAndSave = (params) => request.post('/AIChat/index.php', {
    ...params,
    function: 'sendConversationAndSave'
})

export const getImage = (params) => request.post('/AIChat/index.php', {
    ...params,
    function: 'getImage'
})

export const setConversationMode = (params) => request.post('./index.php', {
    ...params,
    function: 'setConversationMode'
})

export const getUserTextToAudioTaskList = (params) => request.post('./index.php', {
    ...params,
    function: 'getUserTextToAudioTaskList'
})

export const initTextToAudioTask = (params) => request.post('./index.php', {
    ...params,
    function: 'initTextToAudioTask'
})

export const initAudioToTextTask = (params) => request.post('./index.php', {
    ...params,
    function: 'initAudioToTextTask'
})
export const getTaskResult = (params) => request.post('./index.php', {
    ...params,
    function: 'getTaskResult'
})
export const getUserAudioToTextTaskList = (params) => request.post('./index.php', {
    ...params,
    function: 'getUserAudioToTextTaskList'
})
export const addComment = (params) => request.post('./index.php', {
    ...params,
    function: 'addComment'
})
export const removeComment = (params) => request.post('./index.php', {
    ...params,
    function: 'removeComment'
})

export const sendCommentRemind = (params) => request.post('./index.php', {
    ...params,
    function: 'sendCommentRemind'
})
export const archiveConversation = (params) => request.post('./index.php', {
    ...params,
    function: 'archiveConversation'
})

export const sendConversation1 = (params) => request.post('/AIChat/index.php', {
    ...params,
    function: 'sendConversation1'
})

export const getMyOtherTODOsByLabel = (params) => request.post('./index.php', {
    ...params,
    function: 'getMyOtherTODOsByLabel'
})

export function updateTodoSubFinishStatusNew(id, reviewer_id, finish_type, finish_status) {
    return request.post('./index.php', {
        function: 'updateTodoSubFinishStatusNew',
        id,
        reviewer_id,
        finish_type,
        finish_status
    })
}

export const removeZiliao = (params) => request.post('./index.php', {
    ...params,
    function: 'removeZiliao'
})
export const addZiliao = (params) => request.post('./index.php', {
    ...params,
    function: 'addZiliao'
})
export const getZiliaoList = (params) => request.post('./index.php', {
    ...params,
    function: 'getZiliaoList'
})
export const searchISBN = (params) => request.post('./index.php', {
    ...params,
    function: 'searchISBN'
})
export const updateZiliao = (params) => request.post('./index.php', {
    ...params,
    function: 'updateZiliao'
})
export const test = (params) => request.post('/AIChat/index.php', {
    ...params,
    function: 'test'
})
export const addResult = (params) => request.post('./index.php', {
    ...params,
    function: 'addResult'
})
export const getMyTodayResult = (params) => request.post('./index.php', {
    ...params,
    function: 'getMyTodayResult'
})
export const getAllMyResult = (params) => request.post('./index.php', {
    ...params,
    function: 'getAllMyResult'
})
export const updatePrice = (params) => request.post('./index.php', {
    ...params,
    function: 'updatePrice'
})
export const removePrice = (params) => request.post('./index.php', {
    ...params,
    function: 'removePrice'
})
export const getPrices = (params) => request.post('./index.php', {
    ...params,
    function: 'getPrices'
})

export const updateCountry = (params) => request.post('./index.php', {
    ...params,
    function: 'updateCountry'
})
export const removeCountry = (params) => request.post('./index.php', {
    ...params,
    function: 'removeCountry'
})
export const getCountrys = (params) => request.post('./index.php', {
    ...params,
    function: 'getCountrys'
})


export const updateProjectPrice = (params) => request.post('./index.php', {
    ...params,
    function: 'updateProjectPrice'
})
export const removeProjectPrice = (params) => request.post('./index.php', {
    ...params,
    function: 'removeProjectPrice'
})
export const getProjectPrices = (params) => request.post('./index.php', {
    ...params,
    function: 'getProjectPrices'
})
export const getProjectPricesByProject = (params) => request.post('./index.php', {
    ...params,
    function: 'getProjectPricesByProject'
})


export const updateLaw = (params) => request.post('./index.php', {
    ...params,
    function: 'updateLaw'
})
export const getLaws = (params) => request.post('./index.php', {
    ...params,
    function: 'getLaws'
})
export const removeLaw = (params) => request.post('./index.php', {
    ...params,
    function: 'removeLaw'
})
export const createEmailLoginPin = (params) => request.post('./index.php', {
    ...params,
    function: 'createEmailLoginPin'
})
export const verifyEmailLoginPin = (params) => request.post('./index.php', {
    ...params,
    function: 'verifyEmailLoginPin'
})
export const createLoginPin = (params) => request.post('./index.php', {
    ...params,
    function: 'createLoginPin'
})
export const verifyLoginPin = (params) => request.post('./index.php', {
    ...params,
    function: 'verifyLoginPin'
})

export const updateText = (params) => request.post('./index.php', {
    ...params,
    function: 'updateText'
})
export const getTexts = (params) => request.post('./index.php', {
    ...params,
    function: 'getTexts'
})
export const removeText = (params) => request.post('./index.php', {
    ...params,
    function: 'removeText'
})
export const updateTimeTypeForTODO = (params) => request.post('./index.php', {
    ...params,
    function: 'updateTimeTypeForTODO'
})
export const getResultType = (params) => request.post('./index.php', {
    ...params,
    function: 'getResultType'
})
export const updateResultType = (params) => request.post('./index.php', {
    ...params,
    function: 'updateResultType'
})
export const assignChildTodo = (params) => request.post('./index.php', {
    ...params,
    function: 'assignChildTodo'
})
export const getUserAssignedTODOs = (params) => request.post('./index.php', {
    ...params,
    function: 'getUserAssignedTODOs'
})
export function getMidJourney(param) {
    return request.post('/AIChat/index.php', {
        function: 'getMidJourneyNew',
        ...param
    })
}
export function getMidJourneyResult(param) {
    return request.post('/AIChat/index.php', {
        function: 'getMidJourneyResultNew',
        ...param
    })
}
export function changeImage(param) {
    return request.post('/AIChat/index.php', {
        function: 'changeImage',
        ...param
    })
}
export function computeToken(param) {
    return request.post('./index.php', {
        function: 'computeToken',
        ...param
    })
}
export function getLeonardoAI(param) {
    return request.post('/AIChat/index.php', {
        function: 'getLeonardoAI',
        ...param
    })
}
export function getLeonardoAIResult(param) {
    return request.post('/AIChat/index.php', {
        function: 'getLeonardoAIResult',
        ...param
    })
}

export function getLeonardoModelList(param) {
    return request.post('/AIChat/index.php', {
        function: 'getLeonardoModelList',
        ...param
    })
}

export function getTODOsAssignedMeByList(param) {
    return request.post('./index.php', {
        function: 'getTODOsAssignedMeByList',
        ...param
    })
}

export function getChatUserList(param) {
    return request.post('./index.php', {
        function: 'getChatUserList',
        ...param
    })
}
export function getUserConversations(param) {
    return request.post('./index.php', {
        function: 'getUserConversations',
        ...param
    })
}
export function talkToSomeone(param) {
    return request.post('./index.php', {
        function: 'talkToSomeone',
        ...param
    })
}

export function chatSendMessage(param) {
    return request.post('./index.php', {
        function: 'sendMessage',
        ...param
    })
}

export function getConversationMessages(param) {
    return request.post('./index.php', {
        function: 'getConversationMessages',
        ...param
    })
}

export function updateChatConversation(param) {
    return request.post('./index.php', {
        function: 'updateChatConversation',
        ...param
    })
}

export function getEmails() {
    return request.post('./index.php', {
        function: 'getEmails'
    })
}


export function sendEmail(params) {
    return request.post('./index.php', {
        function: 'sendEmail',
        ...params
    })
}

export function batchToSendEmail(params) {
    return request.post('./index.php', {
        function: 'batchToSendEmail',
        ...params
    })
}

export function getEmailsByType(type, sender_id, search_key) {
    return request.post('./index.php', {
        function: 'getEmailsByType',
        type,
        sender_id,
        search_key
    })
}

export function getSenderEmaiList() {
    return request.post('./index.php', {
        function: 'getSenderEmaiList'
    })
}

export function getCompanyEmails() {
    return request.post('./index.php', {
        function: 'getCompanyEmails'
    })
}

export function removeCompanyEmail(id) {
    return request.post('./index.php', {
        function: 'removeCompanyEmail',
        id
    })
}

export function updateCompanyEmail(params) {
    return request.post('./index.php', {
        function: 'updateCompanyEmail',
        ...params
    })
}

export function searchCompanyEmails(key_word) {
    return request.post('./index.php', {
        function: 'searchCompanyEmails',
        key_word
    })
}

export function updateMailReplyStatus(mail_id, status) {
    return request.post('./index.php', {
        function: 'updateMailReplyStatus',
        mail_id,
        status
    })
}

export function getMail(mail_id) {
    return request.post('./index.php', {
        function: 'getMail',
        mail_id
    })
}

export function removeMail(mail_id) {
    return request.post('./index.php', {
        function: 'removeMail',
        mail_id
    })
}

export function assignMailToEmployeeReply(mail_id, employee_id) {
    return request.post('./index.php', {
        function: 'assignMailToEmployeeReply',
        mail_id,
        employee_id
    })
}

export function getFullTimeEmployees() {
    return request.post('./index.php', {
        function: 'getFullTimeEmployees',
    })
}

export function getEmailMatchClientData(email) {
    return request.post('./index.php', {
        function: 'getEmailMatchClientData',
        email
    })
}

export function searchContact(key_word) {
    return request.post('./index.php', {
        function: 'searchContact',
        key_word
    })
}

export function getAllProjects(key_word) {
    return request.post('./index.php', {
        function: 'getAllProjects',
        key_word
    })
}

export function getEmailSigns() {
    return request.post('./index.php', {
        function: 'getEmailSigns'
    })
}

export function removeEmailSign(id) {
    return request.post('./index.php', {
        function: 'removeEmailSign',
        id
    })
}

export function updateEmailSign(params) {
    return request.post('./index.php', {
        function: 'updateEmailSign',
        ...params
    })
}

export function getEmailSign(id) {
    return request.post('./index.php', {
        function: 'getEmailSign',
        id
    })
}
export function getCompanyEmailsV1(id) {
    return request.post('./index.php', {
        function: 'getCompanyEmailsV1',
        id
    })
}

export function getMatchAgent(str) {
    return request.post('/Assistant/index.php', {
        function: 'getMatchAgent',
        str
    })
}
export function getAllAgents() {
    return request.post('/Assistant/index.php', {
        function: 'getAllAgents'
    })
}
export function getRelatedDataFromVectorBase(content, user_id,email) {
    return request.post('/Assistant/index.php', {
        function: 'getRelatedDataFromVectorBase',
        content, user_id,email
    })
}

export function saveUseToken(param) {
    return request.post('./index.php', {
        function: 'saveUseToken',
        ...param
    })
}
<template>
  <div class="navContainer">
    <div class="header">
      <img
        src="../assets/loginTitle.png"
        style="width: 90px; margin-left: 50px"
      />
      <div class="rightPart">
        <span>{{ userName }}</span>
        <img
          src="../assets/exit.svg"
          style="margin-left: 20px; cursor: pointer"
          @click="logout"
        />
      </div>
    </div>
    <div class="nav">
      <div class="intro">
        <div style="display: flex; align-items: center; height: 184px">
          <div style="display: flex; margin-left: 30px; align-items: center">
            <img src="../assets/入职.svg" />
            <div style="margin-left: 20px; padding-top: 8px">
              <div class="intro-title">入职时间</div>
              <div class="intro-content">
                {{ formateDate(user.work_start_time) }}
              </div>
            </div>
          </div>
          <div style="display: flex; align-items: center">
            <div class="item" style="width: 150px">
              <!--                    <div class="intro-title">工作职责</div>-->
              <!--                    <div class="intro-content">{{ user.responsibility }}</div>-->
            </div>
            <div class="item" style="padding-left: 0px">
              <div class="intro-title">您已在无远教育工作</div>
              <div class="intro-content">{{ year }}年{{ day }}天</div>
            </div>
            <div class="item">
              <div class="intro-title">您的管理职称</div>
              <div class="intro-content">{{ management_territory }}</div>
            </div>
            <div class="item">
              <div class="intro-title">您的专业职称</div>
              <div class="intro-content">{{ territorys }}</div>
            </div>
            <div class="item" style="border: none">
              <div class="intro-title">您所属BU</div>
              <div class="intro-content">{{ bunameStr }}</div>
            </div>
          </div>
        </div>
        <div style="display: flex; margin-left: 30px; padding-bottom: 20px">
          <p class="job-duty">工作职责</p>
          <div class="intro-content">{{ user.responsibility }}</div>
          <!--测试使用-->
          <!--                <div class="intro-responsibility">工作职责英语怎么说及英文翻译沪江词库精选工作职责英语怎么说及英文翻译、英语单词怎么写、例句等信息 名词operating duty 相似短语 operating room n.[医]手术室 irregular operating 不正常...</div>-->
        </div>
      </div>
      <div class="fastWay">
        <div style="margin-left: 32px; color: #333333">快速访问</div>
        <div
          class="item"
          v-for="item in urls"
          :key="item.name"
          @click="toPage(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div class="contentContainer">
        <div @click="toTodo" class="item">
          <img src="../assets/icon/事项清单.svg" />
          <div class="item-content">
            <div class="item-title">事项清单</div>
            <div class="item-title1">TodoList</div>
          </div>
        </div>
        <div @click="toManager('meeting')" class="item">
          <img src="../assets/icon/会议记录.svg" />
          <div class="item-content">
            <div class="item-title">会议记录</div>
            <div class="item-title1">Meeting Note</div>
          </div>
        </div>
        <div @click="toCheck" class="item">
          <img src="../assets/icon/工作审核.svg" />
          <div class="item-content">
            <div class="item-title">工作审核</div>
            <div class="item-title1">Work Review</div>
          </div>
        </div>
        <div @click="gotoGantt" class="item">
          <img src="../assets/icon/项目进度.svg" />
          <div class="item-content">
            <div class="item-title">项目进度</div>
            <div class="item-title1">Gantt Chart</div>
          </div>
        </div>
        <div @click="toManager('smartwork')" class="item">
          <img src="../assets/icon/培训提升.svg" />
          <div class="item-content">
            <div class="item-title">培训提升</div>
            <div class="item-title1">SmartWork</div>
          </div>
        </div>
        <div @click="gotoWorkFeedback" class="item">
          <img src="../assets/icon/工作反馈.svg" />
          <div class="item-content">
            <div class="item-title">工作反馈</div>
            <div class="item-title1">Work Feedback</div>
          </div>
        </div>
        <div @click="gotoITRequest" class="item">
          <img src="../assets/icon/技术工单.svg" />
          <div class="item-content">
            <div class="item-title">技术工单</div>
            <div class="item-title1">IT Request</div>
          </div>
        </div>
        <div @click="gotoHoliday" class="item">
          <img src="../assets/icon/假期管理.svg" />
          <div class="item-content">
            <div class="item-title">假期管理</div>
            <div class="item-title1">Holiday Management</div>
          </div>
        </div>

        <div @click="gotoWorkLog" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">工作日志</div>
            <div class="item-title1">Work Log</div>
          </div>
        </div>
        <div @click="gotoETATodo" class="item">
          <img src="../assets/icon/品牌.svg" />
          <div class="item-content">
            <div class="item-title">品牌内容工作清单</div>
            <div class="item-title1">Brand & Content Todo</div>
          </div>
        </div>
        <div @click="gotoStandard" class="item">
          <img src="../assets/icon/工作标准.svg" />
          <div class="item-content">
            <div class="item-title">工作标准</div>
            <div class="item-title1">Work Standard</div>
          </div>
        </div>
        <div @click="gotoExam" class="item">
          <img src="../assets/icon/考试系统.svg" />
          <div class="item-content">
            <div class="item-title">考试系统</div>
            <div class="item-title1">Exam System</div>
          </div>
        </div>
        <div @click="gotoRegulation" class="item">
          <img src="../assets/icon/规章制度.svg" />
          <div class="item-content">
            <div class="item-title">规章制度</div>
            <div class="item-title1">Regulations</div>
          </div>
        </div>
        <div @click="gotoCorrection" class="item">
          <img src="../assets/icon/技能训练.svg" />
          <div class="item-content">
            <div class="item-title">技能训练</div>
            <div class="item-title1">Wording Correction</div>
          </div>
        </div>
        <div @click="gotoSpeech" class="item">
          <img src="../assets/icon/话术字典.svg" />
          <div class="item-content">
            <div class="item-title">话术字典</div>
            <div class="item-title1">Speech Art</div>
          </div>
        </div>
        <div @click="gotProjectTimePoint" class="item">
          <img src="../assets/icon/项目时间点.svg" />
          <div class="item-content">
            <div class="item-title">项目时间点</div>
            <div class="item-title1">Project Point Of Time</div>
          </div>
        </div>
        <div @click="gotoChat" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">客服消息</div>
            <div class="item-title1">Customer Message</div>
          </div>
        </div>
        <div @click="gotoChatAI" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">AI对话</div>
            <div class="item-title1">AI Message</div>
          </div>
        </div>
        <div @click="gotoTransfer" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">语音文字转换</div>
            <div class="item-title1">Transfer</div>
          </div>
        </div>
        <div @click="toManager('employeeList')" class="item">
          <img src="../assets/icon/员工账号管理.svg" />
          <div class="item-content">
            <div class="item-title">员工信息</div>
            <div class="item-title1">Employee information</div>
          </div>
        </div>
        <div @click="gotoInformation" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">资料入库</div>
            <div class="item-title1">Information</div>
          </div>
        </div>
        <div @click="gotoResult" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">成果记录</div>
            <div class="item-title1">Result</div>
          </div>
        </div>
        <div @click="gotoPrice" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">全球定价管理</div>
            <div class="item-title1">Price</div>
          </div>
        </div>
        <div @click="gotoChat" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">聊天</div>
            <div class="item-title1">Chat</div>
          </div>
        </div>
        <div @click="gotoEmails" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">邮件</div>
            <div class="item-title1">Email</div>
          </div>
        </div>
        <div @click="gotoAIContents" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">内容AI创作</div>
            <div class="item-title1">Content AI</div>
          </div>
        </div>
        <div @click="gotoAICustomer" class="item">
          <img src="../assets/icon/工作日志.svg" />
          <div class="item-content">
            <div class="item-title">AI客户开发</div>
            <div class="item-title1">AI Customer Development</div>
          </div>
        </div>
        
      </div>
    </div>
    <el-dialog
      title="提示"
      :visible.sync="showRegulation"
      :close-on-press-escape="false"
      :show-close="false"
      :close-on-click-modal="false"
      width="30%"
    >
      <p align="center">您有未确认的规章制度</p>
      <div slot="footer" class="dialog-footer">
        <el-button
          type="primary"
          class="question-button"
          @click="gotoRegulation"
          >去确认</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="showStandardsCheck"
      :close-on-press-escape="false"
      :show-close="false"
      :close-on-click-modal="false"
      width="30%"
    >
      <p align="center">您有未确认的工作标准</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="question-button" @click="gotoStandard"
          >去确认</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="showMonthly"
      :close-on-press-escape="false"
      :show-close="false"
      :close-on-click-modal="false"
      width="30%"
    >
      <p align="center">您当前月度总结还没填写</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="question-button" @click="gotoMonthly"
          >去填写</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="showWording"
      :close-on-press-escape="false"
      :show-close="false"
      :close-on-click-modal="false"
      width="30%"
    >
      <p align="center">您当前周的表达纠正完成数量还未达标(一周需要完成5个)</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="question-button" @click="gotoWording"
          >去完成</el-button
        >
      </div>
    </el-dialog>
    <el-dialog
      title="提示"
      :visible.sync="showWording1"
      :close-on-press-escape="false"
      :show-close="false"
      :close-on-click-modal="false"
      width="30%"
    >
      <p align="center">您当前周在说道笔记数量还未达标</p>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" class="question-button" @click="gotoWording1"
          >去完成</el-button
        >
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  getUserId,
  removeUserId,
  removeUserName,
  getUserName,
  setBlock,
  setAvatar,
} from "../utils/store";
import {
  getUser,
  getRegulationsUser,
  getUserMonthly,
  getNavUserStandardsCheck,
  checkWordingCorrection,
  getEmployeeNewOKR,
  checkUserAudioNotes,
} from "../api/assistant";
export default {
  data() {
    return {
      userName: "",
      urls: [
        {
          name: "资源中心管理后台",
          url: "http://resourse-center-admin.learningfirst.cn/",
        },
        { name: "admin后台", url: "http://admin.reachable-edu.com/" },
        { name: "渠道沟通系统", url: "http://account.reachable-edu.com/" },
        { name: "FEL内容管理后台", url: "http://material.learningfirst.cn/" },
        { name: "证书系统", url: "http://center.certifi.tech/" },
        {
          name: "报名表导入",
          url: "http://coordinator-import.learningfirst.cn/",
        },
        {
          name: "Canvas",
          url: "http://ai-design.reachable-edu.com/",
        },
        {
          name: "奶茶下单系统后台",
          url: "http://ordering-system-admin.reachable-edu.com",
        },
        {
          name: "数据看板",
          url: "http://data-board.reachable-edu.com/",
        },
        {
          name: "AIffect",
          url: "http://aiffect.reachable-edu.com/",
        },
      ],
      user: {},
      year: 0,
      day: 0,
      showRegulation: false,
      showWording: false,
      showWording1: false,
      showMonthly: false,
      showStandardsCheck: false,
      territorys: "无",
      management_territory: "无",
      okrStr: "无",
      bunameStr: "",
    };
  },
  mounted() {
    this.userId = getUserId();
    this.getUser();
    this.userName = getUserName();
  },
  methods: {
    gotoEmails(){
      this.$router.push({
        path:'/emails'
      })
    },
    gotProjectTimePoint() {
      setBlock("projectTimePoint");
      this.$router.push({
        path: "/adminmanageuser/projectTimePoint",
      });
    },

    toTodo() {
      const routeDta = this.$router.resolve({ path: "/home" });
      window.open(routeDta.href, "_blank");
    },
    toPage(item) {
      window.open(item.url, "_blank");
    },
    toManager(block) {
      setBlock(block);
      this.$router.push({
        path: "/adminmanageuser",
        query: {
          block: block,
        },
      });
    },
    toCheck() {
      setBlock("check");
      this.$router.push({
        path: "/adminmanageuser/checktask",
      });
    },
    gotoSmartWork() {
      this.$router.push({
        path: "/adminmanageuser",
      });
    },
    gotoGantt() {
      window.open("http://anpai.reachable-edu.com/#/projectList", "_blank");
    },
    gotoWorkFeedback() {
      setBlock("feedback");
      this.$router.push({
        path: "/adminmanageuser/comment",
      });
    },
    gotoITRequest() {
      setBlock("IT");
      this.$router.push({
        path: "/adminmanageuser/requestList",
      });
    },
    gotoHoliday() {
      setBlock("holiday");
      this.$router.push({
        path: "/adminmanageuser/applyHoliday",
      });
    },
    gotoWorkLog() {
      getUserMonthly(getUserId()).then((res) => {
        this.showMonthly = !res.data;
        if (this.showMonthly === false) {
          setBlock("log");
          const routeDta = this.$router.resolve({
            path: "/adminmanageuser/dailyLogList",
          });
          window.open(routeDta.href, "_blank");
        }
      });

      // this.$router.push('/workLog/dailyLogList')
    },
    gotoETATodo() {
      setBlock("ETATodo");

      const routeDta = this.$router.resolve({
        path: "/adminmanageuser/ProjectTodo",
      });
      window.open(routeDta.href, "_blank");

      // this.$router.push({
      //     path: '/adminmanageuser/ProjectTodo'
      // })
    },
    gotoStandard() {
      setBlock("standard");
      this.$router.push("/adminmanageuser/standardList");
    },
    gotoExam() {
      setBlock("exam");
      this.$router.push("/adminmanageuser");
    },
    gotoRegulation() {
      setBlock("regulation");
      this.$router.push("/adminmanageuser");
    },
    gotoMonthly() {
      this.$router.push("/adminmanageuser/monthLogList");
    },
    gotoWording() {
      setBlock("correction");
      this.$router.push("/adminmanageuser/correction");
    },
    gotoWording1() {
      window.open("http://shuodao.reachable-edu.com");
    },
    gotoSpeech() {
      setBlock("speech");
      this.$router.push("/adminmanageuser/speech");
    },
    gotoCorrection() {
      setBlock("correction");
      this.$router.push("/adminmanageuser/correction");
    },
    gotoTransfer() {
      setBlock("transfer");
      this.$router.push("/adminmanageuser/audio-to-text");
    },
    gotoChatAI() {
      this.$router.push("/chatai");
    },
    gotoInformation() {
      this.$router.push("/ziliao");
    },
    gotoChat() {
      this.$router.push("/chat");
    },
    gotoPrice() {
      this.$router.push("/price");
    },
    gotoAIContents() {
      this.$router.push("/aicontents");
    },
    gotoAICustomer() {
      this.$router.push("/aicustomer");
    },

    logout() {
      removeUserId();
      removeUserName();
      location.reload();
    },
    getUser() {
      getUser(this.userId).then((res) => {
        this.user = res.data;
        this.computeTime(this.user.work_start_time);

        if (this.user.employee_management_territory) {
          this.management_territory =
            this.user.employee_management +
            "(" +
            this.user.employee_management_territory +
            ")";
        } else {
          this.management_territory = "无";
        }

        if (res.data.headPicUrl) {
          console.log("done", res.data.headPicUrl);
          setAvatar(res.data.headPicUrl);
        }

        if (this.user.bu_names) {
          this.bunameStr = this.user.bu_names.join(",");
        }

        var territorytitle = [];

        if (
          this.user.employee_territorys &&
          this.user.employee_territorys.length > 0
        ) {
          for (var i = 0; i < this.user.employee_territorys.length; i++) {
            var territoryDetail = this.user.employee_territorys[i];
            var title =
              territoryDetail.employee_territory +
              "(" +
              territoryDetail.employee_territory_level +
              ")";
            territorytitle.push(title);
          }
        }
        this.territorys = territorytitle.join(",");
      });

      getRegulationsUser(getUserId()).then((res) => {
        if (res.data.code === 0) {
          console.log(1111, res.data.data);
          for (var index = 0; index < res.data.data.length; index++) {
            var regulation = res.data.data[index];
            if (regulation.check_status) {
              this.showRegulation = false;
            } else {
              this.showRegulation = true;
              break;
            }
          }
        }
      });

      // getUserMonthly(getUserId()).then(res=>{
      //     this.showMonthly = !res.data;
      // })

      getNavUserStandardsCheck(getUserId()).then((res) => {
        this.showStandardsCheck = !res.data;
      });

      checkWordingCorrection(getUserId()).then((res) => {
        this.showWording = !res.data;
      });

      checkUserAudioNotes(getUserId()).then((res) => {
        this.showWording1 = !res.data;
      });
      getEmployeeNewOKR(getUserId()).then((res) => {
        if (res.data === "") {
          this.okrStr = "无";
        } else {
          this.okrStr = res.data.content;
        }
      });
    },
    formateDate(date) {
      const time = new Date(date * 1000);
      return `${time.getFullYear()}年${
        time.getMonth() + 1
      }月${time.getDate()}日`;
    },
    computeTime(time) {
      const duration = new Date().getTime() / 1000 - time;
      this.year = Math.floor(duration / 31536000);
      this.day = Math.floor((duration - this.year * 31536000) / 86400);
    },
    gotoResult() {
      this.$router.push("/result");
    },
    gotoChat() {
      this.$router.push("/wechat");
    },
  },
};
</script>

<style lang="scss" scoped>
.job-duty {
  width: 80px;
  height: 34px;
  line-height: 34px;
  font-size: 14px;
  text-align: center;
  background: #e6f0ff;
  box-sizing: border-box;
  border-radius: 5px 5px 5px 5px;
  font-family: Alibaba PuHuiTi 2-75 SemiBold, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #4a8af4;
  cursor: pointer;
}

.navContainer {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  height: 100vh;
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 50px;
    flex-shrink: 0;
    background: #ffffff;
    box-shadow: 0px 3px 6px 1px rgba(0, 21, 41, 0.12);
    border-radius: 0px 0px 0px 0px;
    .rightPart {
      display: flex;
      align-items: center;
      margin-right: 30px;
      font-family: Alibaba PuHuiTi 2-65 Medium, Alibaba PuHuiTi 20;
      font-weight: normal;
      color: #999999;
      font-size: 16px;
    }
  }
  .nav {
    width: 100%;
    flex: 1;
    overflow-y: scroll;
    .intro {
      width: 1580px;

      margin: 20px auto;
      background: #ffffff;
      box-shadow: 0px 3px 30px 1px rgba(0, 0, 0, 0.08);
      border-radius: 2px 2px 2px 2px;
      .item {
        width: 250px;
        padding: 0 20px;
        box-sizing: border-box;
        text-align: center;
        border-right: 1px solid #eaeaea;
      }
      .intro-title {
        font-family: Alibaba PuHuiTi 2-55 Regular, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #666666;
      }
      .intro-content {
        font-size: 18px;
        font-family: Alibaba PuHuiTi 2-75 SemiBold, Alibaba PuHuiTi 20;
        font-weight: bold;
        color: #333333;
        margin-top: 17px;
      }
      .intro-responsibility {
        margin-left: 20px;
        font-family: Alibaba PuHuiTi 2-75 SemiBold, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #666666;
        font-size: 18px;
        line-height: 28px;
        margin-top: 15px;
      }
    }
    .fastWay {
      width: 1580px;
      margin: 30px auto;
      height: 90px;
      background: #ffffff;
      box-shadow: 0px 3px 30px 1px rgba(0, 0, 0, 0.08);
      border-radius: 2px 2px 2px 2px;
      display: flex;
      align-items: center;
      .item {
        padding: 8px 16px;
        font-size: 14px;
        text-align: center;
        background: #e6f0ff;
        border-radius: 5px 5px 5px 5px;
        font-family: Alibaba PuHuiTi 2-75 SemiBold, Alibaba PuHuiTi 20;
        font-weight: normal;
        color: #4a8af4;
        margin-left: 40px;
        cursor: pointer;
      }
    }
    .contentContainer {
      width: 1600px;
      margin: 40px auto;
      display: flex;
      flex-wrap: wrap;
      .item {
        width: 380px;
        height: 160px;
        background: #ffffff;
        box-shadow: 0px 3px 30px 1px rgba(0, 0, 0, 0.08);
        border-radius: 2px 2px 2px 2px;
        margin: 10px;
        display: flex;
        align-items: center;
        padding: 0 34px;
        box-sizing: border-box;
        cursor: pointer;
        img {
          margin-right: 36px;
        }
        .item-content {
          .item-title {
            font-family: Alibaba PuHuiTi 2-75 SemiBold, Alibaba PuHuiTi 20;
            font-weight: normal;
            color: #333333;
            font-size: 18px;
          }
          .item-title1 {
            font-family: Alibaba PuHuiTi 2-75 SemiBold, Alibaba PuHuiTi 20;
            font-weight: normal;
            color: #333333;
            font-size: 16px;
            margin-top: 8px;
          }
        }
      }
    }
  }
}
</style>
